import React from "react"
import PropTypes from "prop-types"
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

class DeckStatsManaValues extends React.Component {
  constructor(props) {
    super(props);
  }


  render () {
    console.log("DeckStatsManaValues")
    console.log(this.props)
		const renderLineChart = (
      <ResponsiveContainer width={300} height={200}>
        <BarChart
          width={300}
          height={200}
          data={this.props.data}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="creature" name="Creatures" stackId="a" fill="#8884d8" />
          <Bar dataKey="noncreature" name="Non-Creatures" stackId="a" fill="#82ca9d" />
        </BarChart>
      </ResponsiveContainer>
    );
      


    //return (<div className="deck-stats-mana-values"></div>);
    return (<div className="deck-stats-mana-values">
			<h4 className="deck-stats-title">Mana Values</h4>
      {renderLineChart}
      </div>);
  }
}

DeckStatsManaValues.propTypes = {
  data: PropTypes.array
};
export default DeckStatsManaValues
