import React from "react"
import PropTypes from "prop-types"
class LoadingSpinner extends React.Component {
  render () {
    return (
      <React.Fragment>
        <img src={"/assets/ajax-spinner-b66947de7bff481e3cc9124dae93f126fb45a0b76b9c2f71c1e467de293b1de8.gif"} className="ajax-spinner" alt="Loading Indicator"/>
      </React.Fragment>
    );
  }
}

export default LoadingSpinner
